import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'
import { NbDialogRef } from '@nebular/theme'
import { AlertService } from 'src/app/services/alert.service'
import { Util } from 'src/app/utils/util'
import { takeWhile } from 'rxjs/operators'
import { VideoService } from 'src/app/services/video.service'

@Component({
  selector: 'app-create-video-modal',
  templateUrl: './create-video-modal.component.html',
  styleUrls: ['./create-video-modal.component.scss'],
})
export class CreateVideoModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  videoForm: FormGroup
  submitted = false
  editing = false
  arrSituation = []

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateVideoModalComponent>,
    private alert: AlertService,
    private util: Util,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    this.videoForm = this.formBuilder.group({
      nome: ['', Validators.required],
      link: ['', Validators.required],
      situacao: [null, Validators.required],
      descricao: [''],
    })

    this.videoService
      .getSituation()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.arrSituation = resp
      })

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.videoForm.patchValue(this.data)
    }
  }

  onSubmit() {
    this.submitted = true
    if (this.videoForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    if (this.editing) {
      this.update()
    } else {
      this.save()
    }
  }

  save() {
    this.videoService
      .postVideo(this.videoForm.value)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Vídeo cadastrado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  update() {
    this.videoService
      .putVideo(this.videoForm.value, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Vídeo atualizado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  get f() {
    return this.videoForm.controls
  }
}
