import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { AlertService } from 'src/app/services/alert.service'
import { FormService } from 'src/app/services/form.service'
import { NbDialogRef } from '@nebular/theme'
import { Util } from 'src/app/utils/util'
import { ServiceTypeService } from 'src/app/services/service-type.service'
import { ServiceSubtypeService } from 'src/app/services/service-subtype.service'
import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-create-service-subtype-modal',
  templateUrl: './create-service-subtype-modal.component.html',
  styleUrls: ['./create-service-subtype-modal.component.scss'],
})
export class CreateServiceSubtypeModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  arrServiceType: any[]
  serviceSubtypeForm: FormGroup
  submitted = false
  editing = false

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateServiceSubtypeModalComponent>,
    private alert: AlertService,
    private util: Util,
    private serviceTypeService: ServiceTypeService,
    private serviceSubtypeService: ServiceSubtypeService
  ) {}

  ngOnInit() {
    this.serviceSubtypeForm = this.formBuilder.group({
      descricao: ['', Validators.required],
      codigoTipoServico: [null, Validators.required],
      foto: [],
    })

    this.serviceTypeService
      .getServiceType()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(({ value }) => {
        this.arrServiceType = value
      })

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.serviceSubtypeForm.patchValue(this.data)
      if (this.data.temFoto) {
        this.data.foto = this.serviceSubtypeService.getURLDownloadServiceSubType(
          this.data.id
        )
      }
    }
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  download() {
    this.serviceSubtypeService
      .downloadServiceSubType(this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.util.convertBlobToBase64(resp)
      })
  }

  onSubmit() {
    this.submitted = true
    if (this.serviceSubtypeForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    if (this.editing) {
      this.update()
    } else {
      this.save()
    }
  }

  save() {
    const formData = this.util.convertJsonToFormData(
      this.serviceSubtypeForm.value
    )
    this.serviceSubtypeService
      .postServiceSubType(formData)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Subtipo de serviço cadastrado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: this.editing,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  update() {
    const formData = this.util.convertJsonToFormData(
      this.serviceSubtypeForm.value
    )
    this.serviceSubtypeService
      .putServiceSubType(formData, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Subtipo de serviço atualizado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: this.editing,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  get f() {
    return this.serviceSubtypeForm.controls
  }
}
