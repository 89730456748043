import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient, private server: ServerService) {}

  getDocuments() {
    return this.http
      .get<IReturn>(this.server.url(`/Documento`))
      .pipe(catchError(this.server.handleError))
  }

  deleteDocument(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/Documento/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postDocument(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/Documento`), params)
      .pipe(catchError(this.server.handleError))
  }

  putDocument(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/Documento/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  getSituation() {
    return this.http
      .get<any>(this.server.url(`/Dominio/Situacao`))
      .pipe(catchError(this.server.handleError))
  }

  downloadDocument(code: string) {
    return this.http
      .get(this.server.url(`/Documento/download/${code}`), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.server.handleError))
  }
}
