import { HttpClient } from '@angular/common/http'
import { forkJoin } from 'rxjs'
import { User } from '../models/user'
import { Injectable } from '@angular/core'
import { UserService } from './user.service'
import { AuthService } from './auth.service'
import { Permissions } from '../models/permissions'
import { take } from 'rxjs/operators'
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  id: any

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService
  ) {
    const { id } = this.authService.decode()
    this.id = id
  }

  public async getUser(): Promise<User> {
    const res = await forkJoin([
      this.userService.fetchPermissions(),
      this.userService.fetchDataUser(),
    ])
      .pipe(take(1))
      .toPromise()

    const userPermissions = res[0].value
    const dtUser = res[1].value
    localStorage.setItem('user-data', btoa(JSON.stringify(dtUser)))
    localStorage.setItem(
      'user-permissions',
      btoa(JSON.stringify(userPermissions))
    )
    return dtUser
  }

  getEnumPermissions() {
    return Permissions
  }

  validPermissionsActions(...actions) {
    const actionsTemp = [...actions[0]]
    actionsTemp.forEach(action => {
      if (typeof action.permission === 'boolean') {
        action.acesso = action.permission
      } else {
        action.acesso = this.validatePermissions(action.permission)
      }
    })
    return actionsTemp.filter(e => e.acesso).map(e => e.button)
  }

  validatePermissions(userPermission: Permissions): boolean {
    const arrPermissions = localStorage.getItem('user-permissions')

    if (arrPermissions) {
      const permissions = JSON.parse(atob(arrPermissions))
      if (permissions.find(({ recurso }) => recurso === userPermission)) {
        return true
      } else {
        return false
      }
    }
  }
}
