import { Component, OnInit } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'

@Component({
  selector: 'app-modal-approve-disapprove-service',
  templateUrl: './modal-approve-disapprove-service.component.html',
  styleUrls: ['./modal-approve-disapprove-service.component.scss'],
})
export class ModalApproveDisapproveServiceComponent implements OnInit {
  data: any
  approveDisapproveForm: FormGroup

  situacaoServicoOptions = [
    { value: 'Aprovado', label: 'Aprovado' },
    { value: 'Reprovado', label: 'Reprovado' },
  ]

  constructor(
    private nbDialogRef: NbDialogRef<ModalApproveDisapproveServiceComponent>,
    private formBuilder: FormBuilder,
    public formService: FormService
  ) {}

  ngOnInit() {
    if (
      this.data.context === 'painter' &&
      this.data.situacao === 'AguardandoAprovacao'
    ) {
      this.situacaoServicoOptions.push({
        value: 'Aprovado',
        label: 'Comparecer na loja',
      })
    }

    this.approveDisapproveForm = this.formBuilder.group({
      situacao: [
        this.data.situacaoServico ||
          this.data.situacaoQualificacao ||
          this.data.situacaoPintorEquipe ||
          this.data.situacao ||
          null,
        Validators.required,
      ],
      observacao: [''],
    })
  }

  close(item = false) {
    if (
      item &&
      this.formService.validFormAndShowsErrorInput(this.approveDisapproveForm)
    ) {
      return
    }
    this.nbDialogRef.close({
      confirm: item,
      data: this.approveDisapproveForm.value,
    })
  }
}
