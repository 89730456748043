import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { Util } from '../utils/util'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ServiceTypeService {
  constructor(
    private http: HttpClient,
    private server: ServerService,
    private util: Util
  ) {}

  getServiceType() {
    return this.http
      .get<IReturn>(this.server.url(`/TipoDeServico`))
      .pipe(catchError(this.server.handleError))
  }

  deleteServiceType(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/TipoDeServico/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postServiceType(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/TipoDeServico`), params)
      .pipe(catchError(this.server.handleError))
  }

  putServiceType(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/TipoDeServico/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  downloadService(code: string) {
    return this.http
      .get(this.server.url(`/TipoDeServico/download/foto/${code}`), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.server.handleError))
  }

  getURLDownloadService(code: string) {
    return this.server.url(
      `/TipoDeServico/download/foto/${code}?t=${new Date().getTime()}`
    )
  }
}
