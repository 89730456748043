import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, take, retry } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { AlertService } from './alert.service'
import { LoadingBarService } from '@ngx-loading-bar/core'

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alert: AlertService,
    private loadingBar: LoadingBarService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const dupReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.authService.getToken()}`
      ),
    })

    return next.handle(dupReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        const { status } = error
        if (status === 401) {
          this.tryToAuthorize().then(authorized => {
            if (authorized) {
              this.authService.loggedIn.next(true)
              location.reload()
            } else {
              this.alert.warning(
                'Sua sessão expirou. faça login novamente para continuar.'
              )
              setTimeout(() => {
                this.authService.logout()
              }, 2000)
            }
          })
        } else if (status === 400) {
          if ((error.error && error.error.hasError) || error.error) {
            const { errors } = error.error

            if (errors != null) {
              if (Array.isArray(errors)) {
                if (errors.length > 0) {
                  this.alert.warning(errors[0])
                  return throwError(errors[0])
                }
              } else {
                let keys = Object.keys(errors)
                if (keys.length > 0) {
                  let key = keys[0]
                  if (errors.hasOwnProperty(key)) {
                    const element = errors[key]
                    this.alert.warning(element[0])
                    return throwError(errors[0])
                  }
                }
              }
            }
          }
          this.loadingBar.complete()
        } else {
          return throwError(error)
        }
      })
    )
  }

  async tryToAuthorize(): Promise<boolean> {
    const { value } = await this.authService
      .refreshToken()
      .pipe(take(1))
      .toPromise()
    const { token } = value

    if (token) {
      localStorage.setItem('access-token', token.accessToken)
      localStorage.setItem('refresh-token', token.refreshToken)
    }

    return value.authenticated ? true : false
  }
}
