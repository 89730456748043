import { Injectable } from '@angular/core'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { Util } from '../utils/util'

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private http: HttpClient,
    private server: ServerService,
    private util: Util
  ) {}

  getEvents() {
    return this.http
      .get<IReturn>(this.server.url(`/Evento`))
      .pipe(catchError(this.server.handleError))
  }

  deleteEvent(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/Evento/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postEvent(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/Evento`), params)
      .pipe(catchError(this.server.handleError))
  }

  putEvent(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/Evento/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  getSituation() {
    return this.http
      .get<any>(this.server.url(`/Dominio/Situacao`))
      .pipe(catchError(this.server.handleError))
  }
}
