import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth-guard.service'
import { PermissionGuard } from './guards/permission.guard'

interface RouterSearchable {
  path?: string
  component?: any
  searchName?: string
  redirectTo?: string
  pathMatch?: string
  canActivate?: any
  data?: { role: string }
  children?: RouterSearchable[]
  outlet?: string
}

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'security',
    children: [
      {
        path: 'user',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/security/register-user/register-user.module').then(
            m => m.RegisterUserModule
          ),
      },
      {
        path: 'group',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/security/register-group/register-group.module').then(
            m => m.RegisterGroupModule
          ),
      },
      {
        path: 'group-permission',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import(
            './pages/security/group-permission/group-permission.module'
          ).then(m => m.GroupPermissionModule),
      },
    ],
  },
  {
    path: 'register',
    children: [
      {
        path: 'painter',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/painter/list/list-painter.module').then(
            m => m.ListPainterModule
          ),
      },
      {
        path: 'createPainter',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/painter/create/painter.module').then(
            m => m.PainterModule
          ),
      },
      {
        path: 'editPainter/:code',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/painter/create/painter.module').then(
            m => m.PainterModule
          ),
      },
      {
        path: 'servicetype',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import(
            './pages/register/servicetype/list/list-service-type.module'
          ).then(m => m.ListServiceTypeModule),
      },
      {
        path: 'service-subtype',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import(
            './pages/register/serviceSubtype/list/list-service-subtype.module'
          ).then(m => m.ListServiceSubtypeModule),
      },
      {
        path: 'event',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/event/list-event/list-event.module').then(
            m => m.ListEventModule
          ),
      },
      {
        path: 'video',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/video/list-video/list-video.module').then(
            m => m.ListVideoModule
          ),
      },
      {
        path: 'document',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import(
            './pages/register/document/list-document/list-document.module'
          ).then(m => m.ListDocumentModule),
      },
      {
        path: 'banner',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/register/banner/list-banner/list-banner.module').then(
            m => m.ListBannerModule
          ),
      },
    ],
  },
  {
    path: 'administration',
    children: [
      {
        path: 'customers',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/customers/list/customers.module').then(
            m => m.CustomersModule
          ),
      },
      {
        path: 'detailsCustomers/:code',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/customers/details/details-customers.module').then(
            m => m.DetailsCustomersModule
          ),
      },
      {
        path: 'painterIndication',
        canLoad: [AuthGuard, PermissionGuard],
        loadChildren: () =>
          import('./pages/painter-indication/painter-indication.module').then(
            m => m.PainterIndicationModule
          ),
      },
    ],
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomeModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
