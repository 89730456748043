import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { GroupService } from 'src/app/services/group.service'
import { AlertService } from 'src/app/services/alert.service'
import { Util } from 'src/app/utils/util'
import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-register-group-modal',
  templateUrl: './register-group-modal.component.html',
  styleUrls: ['./register-group-modal.component.scss'],
})
export class RegisterGroupModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  code: any
  submitted = false
  data: any
  companies = []
  editing = false

  @ViewChild('nome') nome
  @ViewChild('descricao') descricao
  constructor(
    private nbDialogRef: NbDialogRef<RegisterGroupModalComponent>,
    private groupService: GroupService,
    private alert: AlertService,
    private util: Util
  ) {}

  ngOnInit() {
    this.code = this.data?.id

    if (this.code) {
      this.editing = true
    } else {
      this.data = {
        nome: '',
        descricao: '',
        id: '',
      }
    }
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  onSubmit() {
    this.submitted = true
    if (
      !this.util.isEmpty(this.nome.value) &&
      !this.util.isEmpty(this.descricao.value)
    ) {
      if (this.editing) {
        this.update()
      } else {
        this.save()
      }
    }
  }

  save() {
    this.groupService
      .save(this.data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(({ value }) => {
        this.alert.success('Grupo cadastrado')
        this.submitted = false
        this.close()
      })
  }

  update() {
    this.groupService
      .update(this.code, this.data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Grupo atualizado')
          this.submitted = false
          this.close()
        },
        ({ error }) => {
          if (error.hasError) {
            this.alert.warning(error.errors[0])
          }
        }
      )
  }
  close(item?) {
    this.nbDialogRef.close(item)
  }
}
