import { Injectable } from '@angular/core'
import { CurrentUserService } from '../services/current-user.service'
import { HttpParams } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import * as moment from 'moment'
import 'moment/min/locales'

@Injectable({
  providedIn: 'root',
})
export class Util {
  constructor(
    public uService: CurrentUserService,
    private route: ActivatedRoute
  ) {}

  controlActionsTable(columns, ...permissions) {
    if (
      !this.uService.validatePermissions(permissions[0]) &&
      !this.uService.validatePermissions(permissions[1])
    ) {
      return columns.filter(e => e.type !== 'actions')
    } else {
      return columns
    }
  }

  convertToCPF = value => {
    let part1, part2, part3, part4

    part1 = value.substring(0, 3)
    part2 = value.substring(3, 6)
    part3 = value.substring(6, 9)
    part4 = value.substring(9, 11)

    return `${part1}.${part2}.${part3}-${part4}`
  }

  isFile(input) {
    return 'File' in window && input instanceof File
  }

  convertJsonToFormData(value) {
    if (value) {
      const formData = new FormData()
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          const element = value[key]
          if (element) {
            if (typeof element === 'object' && element.length === 1) {
              formData.append(key, element[0])
            } else {
              formData.append(key, element)
            }
          }
        }
      }
      return formData
    }
  }

  convertBlobToBase64(response, name = 'Documento') {
    const nameFile = response.headers.get('content-disposition')
    const { body } = response

    if (nameFile) {
      name = nameFile.split(';')[1].replace(' filename=', '')
    }

    const reader = new FileReader()
    reader.readAsDataURL(body)
    reader.onloadend = e => {
      const a = document.createElement('a')
      a.href = reader.result.toString()
      a.download = name.replace(/(")/g, '')
      a.click()
    }
  }

  base64ToDownload(base64, name = 'Documento') {
    const a = document.createElement('a')
    a.href = base64
    a.download = `${name}`
    a.click()
  }

  convertDateTimeToDate(date: string) {
    if (!this.isEmpty(date)) {
      return moment(new Date(date)).format('DD/MM/YYYY')
    }
  }

  convertDateTime(date: string) {
    if (!this.isEmpty(date)) {
      return moment(moment(date, 'DD/MM/YYYY')).format()
    } else {
      return null
    }
  }

  convertDate(date: string) {
    if (!this.isEmpty(date)) {
      return moment(moment(date, 'DD/MM/YYYY')).format('DD/MM/YYYY')
    }
  }

  treatDate(date: string) {
    if (!this.isEmpty(date)) {
      const hasDate = date.split('T')
      if (hasDate.length === 2) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
      }
    }
  }

  isEmpty = value =>
    value === '' || value === ' ' || value === null || value === undefined
      ? true
      : false

  isCPF = value => new RegExp(/\d{11}/).test(value)

  isDate = value => new Date(value)

  FiletoBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  takeParamsInObjToURL(objParams) {
    let params

    if (objParams) {
      params = new HttpParams()
      for (const key in objParams) {
        if (objParams.hasOwnProperty(key)) {
          if (!this.isEmpty(objParams[key])) {
            params = params.set(key, objParams[key])
          }
        }
      }
    } else {
      params = ''
    }
    return params
  }

  dateNow(value) {
    return value ? moment().format('DD/MM/YYYY') : ''
  }

  getUserToLocalStorage() {
    return JSON.parse(localStorage.getItem('user'))
  }

  getQueryParams(param) {
    return this.route.snapshot.queryParamMap.get(param)
  }

  applyMaskPhone = value => {
    if (value) {
      if (value.length == 10) {
        let part1, part2, part3
        part1 = value.substring(0, 2)
        part2 = value.substring(2, 6)
        part3 = value.substring(6, 10)
        return `(${part1}) ${part2}-${part3}`
      }

      if (value.length == 11) {
        let part1, part2, part3, part4
        part1 = value.substring(0, 2)
        part2 = value.substring(2, 3)
        part3 = value.substring(3, 7)
        part4 = value.substring(7, 11)
        return `(${part1}) ${part2} ${part3}-${part4}`
      }
    }
  }

  applyMaskCEP = value => {
    const formatedValue = value?.toString()
    const part1 = formatedValue.substr(0, 2)
    const part2 = formatedValue.substr(2, 3)
    const part3 = formatedValue.substr(5, formatedValue.length)
    return `${part1}.${part2}-${part3}`
  }

  applyMaskDate = value => moment(value).format('DD/MM/YYYY')

  validDateAndApplyMaskDate(value) {
    if (!this.isEmpty(value)) {
      return moment(value).format('DD/MM/YYYY')
    } else {
      return null
    }
  }

  applyMaskDateTime = value =>
    moment(value)
      .locale('pt-br')
      .format('DD/MM/YYYY H:mm:ss')

  applyMaskCPForCNPJ = value => {
    const cnpjCpf = value.replace(/\D/g, '')

    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    }

    return cnpjCpf.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    )
  }

  applyMaskCNPJ = value => {
    let part1, part2, part3, part4, part5

    part1 = value.substring(0, 2)
    part2 = value.substring(2, 5)
    part3 = value.substring(5, 8)
    part4 = value.substring(8, 12)
    part5 = value.substring(12, 14)

    return `${part1}.${part2}.${part3}/${part4}-${part5}`
  }

  applyMaskCPF = value => {
    if (this.isCPF(value)) {
      let part1, part2, part3, part4

      part1 = value.substring(0, 3)
      part2 = value.substring(3, 6)
      part3 = value.substring(6, 9)
      part4 = value.substring(9, 11)

      return `${part1}.${part2}.${part3}-${part4}`
    }
  }

  applyMaskSituation(value) {
    let situation
    switch (value) {
      case 'NaoIniciado':
        situation = 'Não iniciado'
        break
      case 'AguardandoAprovacao':
        situation = 'Aguardando Aprovação'
        break
      case 'EmAndamento':
        situation = 'Em andamento'
        break
      case 'ServicoAgendado':
        situation = 'Serviço agendado'
        break
      case 'ServicoRealizado':
        situation = 'Serviço realizado'
        break
      case 'AguardandoAvaliacao':
        situation = 'Aguardando avaliação'
        break
      case 'ComparecerNaLoja':
        situation = 'Comparecer na Loja'
        break

      default:
        situation = value
        break
    }
    return situation
  }
}
