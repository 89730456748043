import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NbDialogRef } from '@nebular/theme'
import { FormService } from 'src/app/services/form.service'
import { Util } from 'src/app/utils/util'
import { AuthService } from 'src/app/services/auth.service'
import { LoginChangePassword } from 'src/app/models/login'
import { AlertService } from 'src/app/services/alert.service'
import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  private isAlive = true
  user = new LoginChangePassword()
  changePasswordForm: FormGroup
  submitted = false

  constructor(
    private nbDialogRef: NbDialogRef<ChangePasswordComponent>,
    private formBuilder: FormBuilder,
    public formService: FormService,
    private util: Util,
    private authService: AuthService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    const { nome } = this.util.getUserToLocalStorage()

    this.changePasswordForm = this.formBuilder.group({
      userName: [{ value: nome, disabled: true }, Validators.required],
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', Validators.required],
    })
  }

  onSubmit() {
    this.submitted = true

    if (this.changePasswordForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }
    this.user = this.changePasswordForm.getRawValue()
    this.authService
      .changePassword(this.user)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.alert.success('Senha alterada')
        this.close()
      })
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  get f() {
    return this.changePasswordForm.controls
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }
}
