import { ServerService } from './server.service'
import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { User } from '../models/user'
import { JwtHelperService } from '@auth0/angular-jwt'
import * as jwt_decode from 'jwt-decode'
import { HttpClient } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { Login, LoginChangePassword } from '../models/login'
import { IReturn } from '../models/return'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = new BehaviorSubject<boolean>(false)

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private server: ServerService
  ) {
    this.loggedIn.next(this.getToken() ? true : false)
  }

  getToken(): string {
    return localStorage.getItem('access-token')
  }

  logIn(user: Login) {
    return this.http
      .post<IReturn>(this.server.url('/Login'), user)
      .pipe(catchError(this.server.handleError))
  }

  signUp(email: string, password: string): Observable<User> {
    return this.http
      .post<User>(this.server.url('/register'), {
        email,
        password,
      })
      .pipe(catchError(this.server.handleError))
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable()
  }

  logout() {
    window.location.reload()
    localStorage.clear()
    // localStorage.removeItem('access-token')
    // localStorage.removeItem('person')
    // localStorage.removeItem('user-permissions')
    // localStorage.removeItem('refresh-token')
  }

  decode() {
    let token = { sid: '', id: '' }
    if (this.getToken()) {
      token = jwt_decode(this.getToken())
      return token
    }
    return token
  }

  resetPassword(params: LoginChangePassword) {
    return this.http
      .post<any>(this.server.url('/Login/senha/resetar'), params)
      .pipe(catchError(this.server.handleError))
  }

  changePassword(params: LoginChangePassword) {
    return this.http
      .post<IReturn>(this.server.url(`/Login/senha/alterar`), params)
      .pipe(catchError(this.server.handleError))
  }

  getIp() {
    return this.http.get<any>('http://api.ipify.org/?format=json')
  }

  getCaptcha(ip: string) {
    return this.http
      .post<any>(this.server.url('/Login/captcha'), { ip })
      .pipe(catchError(this.server.handleError))
  }

  registerOperator() {
    // return this.http.post<any>(`${this.BASE_URL}`)
  }

  refreshToken() {
    const { id } = this.decode()
    const refreshToken = localStorage.getItem('refresh-token')

    return this.http
      .post<any>(this.server.url('/Login'), {
        userName: id,
        password: refreshToken,
        grantTypes: 'refresh_token',
        resultado: 0,
      })
      .pipe(catchError(this.server.handleError))
  }
}
