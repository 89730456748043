import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'
import { NbDialogRef } from '@nebular/theme'
import { AlertService } from 'src/app/services/alert.service'
import { DocumentService } from 'src/app/services/document.service'
import { Util } from 'src/app/utils/util'
import { takeWhile } from 'rxjs/operators'
import { FileUploadValidators } from '@iplab/ngx-file-upload'

@Component({
  selector: 'app-create-document-modal',
  templateUrl: './create-document-modal.component.html',
  styleUrls: ['./create-document-modal.component.scss'],
})
export class CreateDocumentModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  documentForm: FormGroup
  submitted = false
  editing = false
  arrSituation = []

  private anexoControl = new FormControl(null, [
    Validators.required,
    FileUploadValidators.filesLimit(1),
  ])

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateDocumentModalComponent>,
    private alert: AlertService,
    private util: Util,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    this.documentForm = this.formBuilder.group({
      descricao: ['', Validators.required],
      anexo: this.anexoControl,
      situacao: [null, Validators.required],
    })

    this.documentService
      .getSituation()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.arrSituation = resp
      })

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.documentForm.patchValue(this.data)
      this.f.anexo.setValidators([])
      this.f.anexo.updateValueAndValidity()
    }
  }

  onSubmit() {
    this.submitted = true
    if (this.documentForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    const dataTemp = this.util.convertJsonToFormData(this.documentForm.value)

    if (this.editing) {
      this.update(dataTemp)
    } else {
      this.save(dataTemp)
    }
  }

  save(data) {
    this.documentService
      .postDocument(data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Documento cadastrado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  update(data) {
    this.documentService
      .putDocument(data, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Documento atualizado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  donwloadFile() {
    this.documentService
      .downloadDocument(this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.util.convertBlobToBase64(resp)
      })
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  get f() {
    return this.documentForm.controls
  }
}
