import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ErrorsInputComponent } from './errors-input.component'

@NgModule({
  declarations: [ErrorsInputComponent],
  imports: [CommonModule],
  exports: [ErrorsInputComponent],
})
export class ErrorsInputModule {}
