import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private http: HttpClient, private server: ServerService) {}

  getGroups() {
    return this.http
      .get<IReturn>(this.server.url(`/Grupo`))
      .pipe(catchError(this.server.handleError))
  }

  save(params) {
    return this.http.post<any>(this.server.url(`/Grupo`), params)
  }

  fetchId(id) {
    return this.http.get<any>(this.server.url(`/Grupo/${id}`))
  }

  deleteRegisterGroup(id) {
    return this.http.delete<any>(this.server.url(`/Grupo/${id}`))
  }

  update(id, params) {
    return this.http
      .put<IReturn>(this.server.url(`/Grupo/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  enableDisableGroup(id) {
    return this.http
      .put<IReturn>(this.server.url(`/Grupo/ativarDesativar/${id}`), {})
      .pipe(catchError(this.server.handleError))
  }

  getGroupPermissions(id: string) {
    const query = id ? `?grupoId=${id}` : ''
    return this.http
      .get<any>(this.server.url(`/GrupoFuncao${query}`))
      .pipe(catchError(this.server.handleError))
  }

  fetchPermissions(grupoId: string) {
    return this.http.get<any>(
      this.server.url(`/PermissaoGrupo?GrupoId=${grupoId}`)
    )
  }

  savePermission(params) {
    return this.http
      .post<any>(this.server.url(`/GrupoFuncao`), params)
      .pipe(catchError(this.server.handleError))
  }

  deletePermission(id: string) {
    const query = id ? `?id=${id}` : ''
    return this.http
      .delete<any>(this.server.url(`/GrupoFuncao/id${query}`))
      .pipe(catchError(this.server.handleError))
  }
}
