import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'
import { NbDialogRef } from '@nebular/theme'
import { AlertService } from 'src/app/services/alert.service'
import { Util } from 'src/app/utils/util'
import { EventService } from 'src/app/services/event.service'
import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss'],
})
export class CreateEventModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  eventForm: FormGroup
  submitted = false
  editing = false
  arrSituation = []

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateEventModalComponent>,
    private alert: AlertService,
    private util: Util,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.eventForm = this.formBuilder.group({
      nome: ['', Validators.required],
      descricao: [''],
      data: ['', [Validators.required, this.formService.validaData]],
      local: ['', Validators.required],
      situacao: [null, Validators.required],
    })

    this.eventService
      .getSituation()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.arrSituation = resp
      })

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.eventForm.patchValue({
        ...this.data,
        data: this.util.validDateAndApplyMaskDate(this.data.data),
      })
    }
  }

  onSubmit() {
    this.submitted = true
    if (this.eventForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    const dataTemp = { ...this.eventForm.value }
    dataTemp.data = this.util.convertDateTime(dataTemp.data)

    if (this.editing) {
      this.update(dataTemp)
    } else {
      this.save(dataTemp)
    }
  }

  save(data) {
    this.eventService
      .postEvent(data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Evento cadastrado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  update(data) {
    this.eventService
      .putEvent(data, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Evento atualizado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  get f() {
    return this.eventForm.controls
  }
}
