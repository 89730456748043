import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(private http: HttpClient, private server: ServerService) {}

  getVideos() {
    return this.http
      .get<IReturn>(this.server.url(`/VideoAula`))
      .pipe(catchError(this.server.handleError))
  }

  deleteVideo(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/VideoAula/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postVideo(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/VideoAula`), params)
      .pipe(catchError(this.server.handleError))
  }

  putVideo(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/VideoAula/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  getSituation() {
    return this.http
      .get<any>(this.server.url(`/Dominio/Situacao`))
      .pipe(catchError(this.server.handleError))
  }
}
