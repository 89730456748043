import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { Util } from '../utils/util'
import { IReturn } from '../models/return'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ServiceSubtypeService {
  constructor(
    private http: HttpClient,
    private server: ServerService,
    private util: Util
  ) {}

  getServiceSubType() {
    return this.http
      .get<IReturn>(this.server.url(`/SubTipoDeServico`))
      .pipe(catchError(this.server.handleError))
  }

  deleteServiceSubType(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/SubTipoDeServico/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postServiceSubType(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/SubTipoDeServico`), params)
      .pipe(catchError(this.server.handleError))
  }

  putServiceSubType(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/SubTipoDeServico/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  downloadServiceSubType(code: string) {
    return this.http
      .get(this.server.url(`/SubTipoDeServico/download/foto/${code}`), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.server.handleError))
  }

  getURLDownloadServiceSubType(code: string) {
    return this.server.url(`/SubTipoDeServico/download/foto/${code}`)
  }
}
