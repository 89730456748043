export enum Permissions {
  //  Empresa
  ConsultarEmpresa = 'ConsultarEmpresa',
  CadastrarEmpresa = 'CadastrarEmpresa',
  AtualizarEmpresa = 'AtualizarEmpresa',
  DeletarEmpresa = 'DeletarEmpresa',
  // Usuario
  ConsultarUsuario = 'ConsultarUsuario',
  CadastrarUsuario = 'CadastrarUsuario',
  AtualizarUsuario = 'AtualizarUsuario',
  AtivarDesativarUsuario = 'AtivarDesativarUsuario',
  // Grupo
  ConsultarGrupo = 'ConsultarGrupo',
  CadastrarGrupo = 'CadastrarGrupo',
  AtualizarGrupo = 'AtualizarGrupo',
  AtivarDesativarGrupo = 'AtivarDesativarGrupo',
  // Perfil
  ConsultarPerfil = 'ConsultarPerfil',
  CadastrarPerfil = 'CadastrarPerfil',
  AtualizarPerfil = 'AtualizarPerfil',
  DeletarPerfil = 'DeletarPerfil',
  // Permissoes Grupo
  ConsultarPermissoesGrupo = 'ConsultarPermissoesGrupo',
  CadastrarPermissaoGrupo = 'CadastrarPermissaoGrupo',
  DeletarPermissaoGrupo = 'DeletarPermissaoGrupo',
  // Permissão usuário
  ConsultarPermissoesUsuario = 'ConsultarPermissoesUsuario',
  CadastrarPermissaoUsuario = 'CadastrarPermissaoUsuario',
  DeletarPermissaoUsuario = 'DeletarPermissaoUsuario',
  // Opcicionais
  ConsultarOpcionais = 'ConsultarOpcionais',
  CadastrarOpcionais = 'CadastrarOpcionais',
  AtualizarOpcionais = 'AtualizarOpcionais',
  DeletarOpcionais = 'DeletarOpcionais',
  CadastrarCidade = 'CadastrarCidade',
  // Qualificacao
  CadastrarQualificacao = 'CadastrarQualificacao',
  ConsultarQualificacao = 'ConsultarQualificacao',
  AtualizarQualificacao = 'AtualizarQualificacao',
  DeletarQualificacao = 'DeletarQualificacao',
  // Funcao
  CadastrarFuncao = 'CadastrarFuncao',
  ConsultarFuncao = 'ConsultarFuncao',
  AtualizarFuncao = 'AtualizarFuncao',
  DeletarFuncao = 'DeletarFuncao',
  // Pintor
  ConsultarPintor = 'ConsultarPintor',
  CadastrarPintor = 'CadastrarPintor',
  AtualizarPintor = 'AtualizarPintor',
  DeletarPintor = 'DeletarPintor',
  AtivarDesativarPintor = 'AtivarDesativarPintor',
  AprovarReprovarPintor = 'AprovarReprovarPintor',
  // PintorEquipe
  ConsultarPintorEquipe = 'ConsultarPintorEquipe',
  CadastrarPintorEquipe = 'CadastrarPintorEquipe',
  AtualizarPintorEquipe = 'AtualizarPintorEquipe',
  AprovarReprovarPintorEquipe = 'AprovarReprovarPintorEquipe',
  DeletarPintorEquipe = 'DeletarPintorEquipe',
  // VisitaCliente
  ConsultarClienteVisita = 'ConsultarClienteVisita',
  AtualizarClienteVisita = 'AtualizarClienteVisita',
  DetalharClienteVisita = 'DetalharClienteVisita',
  DeletarClienteVisita = 'DeletarClienteVisita',
  // Serviço Foto
  ConsultarServicoFoto = 'ConsultarServicoFoto',
  CadastrarServicoFoto = 'CadastrarServicoFoto',
  DeletarServicoFoto = 'DeletarServicoFoto',
  // Serviço
  ConsultarServico = 'ConsultarServico',
  CadastrarServico = 'CadastrarServico',
  AtualizarServico = 'AtualizarServico',
  AprovarReprovarServico = 'AprovarReprovarServico',
  DeletarServico = 'DeletarServico',
  // Forma pagamento
  ConsultarFormaPagamento = 'ConsultarFormaPagamento',
  // Cliente
  CadastrarCliente = 'CadastrarCliente',
  // Tipo Servico
  ConsultarTipoServico = 'ConsultarTipoServico',
  CadastrarTipoServico = 'CadastrarTipoServico',
  AtualizarTipoServico = 'AtualizarTipoServico',
  DeletarTipoServico = 'DeletarTipoServico',
  // Sub Servico
  ConsultarSubTipoServico = 'ConsultarSubTipoServico',
  CadastrarSubTipoServico = 'CadastrarSubTipoServico',
  AtualizarSubTipoServico = 'AtualizarSubTipoServico',
  DeletarSubTipoServico = 'DeletarSubTipoServico',
  // ArquivoPintor
  ConsultarPintorArquivo = 'ConsultarPintorArquivo',
  CadastrarPintorArquivo = 'CadastrarPintorArquivo',
  DeletarPintorArquivo = 'DeletarPintorArquivo',

  // IndicacaoPintor
  ConsultarIndicacaoPintor = 'ConsultarIndicacaoPintor',
  CadastrarIndicacaoPintor = 'CadastrarIndicacaoPintor',

  // ClienteVisitaAnotacao
  CadastrarClienteVisitaAnotacao = 'CadastrarClienteVisitaAnotacao',

  // ReprovarClienteAvaliacao
  CadastrarClienteAvaliacao = 'CadastrarClienteAvaliacao',
  AprovarReprovarClienteAvaliacao = 'AprovarReprovarClienteAvaliacao',

  // Evento
  ConsultarEvento = 'ConsultarEvento',
  CadastrarEvento = 'CadastrarEvento',
  AtualizarEvento = 'AtualizarEvento',
  DeletarEvento = 'DeletarEvento',

  // Documento
  ConsultarDocumento = 'ConsultarDocumento',
  CadastrarDocumento = 'CadastrarDocumento',
  AtualizarDocumento = 'AtualizarDocumento',
  DeletarDocumento = 'DeletarDocumento',

  // VideoAula
  ConsultarVideoAula = 'ConsultarVideoAula',
  CadastrarVideoAula = 'CadastrarVideoAula',
  AtualizarVideoAula = 'AtualizarVideoAula',
  DeletarVideoAula = 'DeletarVideoAula',

  // Banner
  ConsultarBanner = 'ConsultarBanner',
  CadastrarBanner = 'CadastrarBanner',
  AtualizarBanner = 'AtualizarBanner',
  DeletarBanner = 'DeletarBanner',
}
