import { RegisterGroupModalComponent } from './pages/security/register-group/register-group-modal/register-group-modal.component'
import { RegisterUserModalComponent } from './pages/security/register-user/register-user-modal/register-user-modal.component'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, LOCALE_ID } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  registerLocaleData,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import { NbDateFnsDateModule } from '@nebular/date-fns'
import { JwtModule } from '@auth0/angular-jwt'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import {
  NbThemeModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbSidebarModule,
  NbToastrModule,
  NbMenuModule,
  NbCardModule,
  NbSelectModule,
  NbInputModule,
  NbButtonModule,
  NbDialogModule,
  NbAccordionModule,
  NbListModule,
  NbIconModule,
  NbTooltipModule,
} from '@nebular/theme'
import { AuthGuard } from './guards/auth-guard.service'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { Interceptor } from './services/intercept.service'
import { NgxMaskModule } from 'ngx-mask'
import * as ptBR from 'date-fns/locale/pt-BR/index.js'
import { TopbarModule } from './components/topbar/topbar.module'
import { SidebarModule } from './components/sidebar/sidebar.module'
import { FooterModule } from './components/footer/footer.module'
import { ErrorsInputModule } from './components/errors-input/errors-input.module'
import { ChangePasswordComponent } from './components/change-password/change-password.component'
import { ModalConfirmDeletionComponent } from './components/modal-confirm-deletion/modal-confirm-deletion.component'
import { CreateServicetypeModalComponent } from './pages/register/servicetype/create-servicetype-modal/create-servicetype-modal.component'
import { FileUploadModule } from '@iplab/ngx-file-upload'
import { CreateServiceSubtypeModalComponent } from './pages/register/serviceSubtype/create-service-subtype-modal/create-service-subtype-modal.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { ProfileComponent } from './components/profile/profile.component'
import { CreateEventModalComponent } from './pages/register/event/create-event-modal/create-event-modal.component'
import { CreateVideoModalComponent } from './pages/register/video/create-video-modal/create-video-modal.component'
import { CreateDocumentModalComponent } from './pages/register/document/create-document-modal/create-document-modal.component'
import { CreateBannerModalComponent } from './pages/register/banner/create-banner-modal/create-banner-modal.component'
import { ModalApproveDisapproveServiceComponent } from './pages/register/painter/create/modal-approve-disapprove-service/modal-approve-disapprove-service.component'
registerLocaleData(localePt)

export function tokenGetter() {
  return localStorage.getItem('access_token')
}
@NgModule({
  declarations: [
    AppComponent,
    RegisterUserModalComponent,
    RegisterGroupModalComponent,
    ChangePasswordComponent,
    ModalConfirmDeletionComponent,
    ModalApproveDisapproveServiceComponent,
    CreateServicetypeModalComponent,
    CreateServiceSubtypeModalComponent,
    ProfileComponent,
    CreateEventModalComponent,
    CreateVideoModalComponent,
    CreateDocumentModalComponent,
    CreateBannerModalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    NbCardModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbEvaIconsModule,
    NbIconModule,
    NbTooltipModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    NbDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: ptBR },
      formatOptions: { locale: ptBR },
    }),
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbLayoutModule,
    TopbarModule,
    SidebarModule,
    FooterModule,
    NgSelectModule,
    ErrorsInputModule,
    NbAccordionModule,
    NbListModule,
  ],
  providers: [
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  entryComponents: [
    RegisterUserModalComponent,
    RegisterGroupModalComponent,
    ChangePasswordComponent,
    ModalConfirmDeletionComponent,
    ModalApproveDisapproveServiceComponent,
    CreateServicetypeModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
