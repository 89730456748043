import { Component, OnInit, Input } from '@angular/core'
import {
  NbSidebarService,
  NbSearchService,
  NbMenuService,
  NbDialogService,
} from '@nebular/theme'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { UserService } from '../../services/user.service'
import { Util } from 'src/app/utils/util'
import { ChangePasswordComponent } from '../change-password/change-password.component'
import { ProfileComponent } from '../profile/profile.component'

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Input()
  showNotificationButton = false

  @Input()
  showMessageButton = false
  user = {
    menu: [
      {
        title: 'Meus dados',
        icon: 'person-outline',
        expanded: false,
        children: [
          {
            title: 'Perfil',
            icon: 'person-outline',
          },
          {
            title: 'Mudar Senha',
            icon: 'lock-outline',
          },
        ],
      },
      { title: 'Sair', icon: 'log-out-outline' },
    ],
    nome: '',
    login: '',
  }
  routerLogo: string

  constructor(
    private dialogService: NbDialogService,
    private sidebarService: NbSidebarService,
    private searchService: NbSearchService,
    private router: Router,
    private menuService: NbMenuService,
    private authService: AuthService,
    private util: Util,
    private userService: UserService
  ) {
    this.searchService.onSearchSubmit().subscribe(async ({ term }: any) => {
      await this.router.navigate(['/functionality-search'], {
        queryParams: { term },
      })
    })
  }

  ngOnInit() {
    this.menuService.onItemClick().subscribe(({ item }) => {
      this.onItemSelection(item)
    })
    this.fetchUser()
  }

  toggle() {
    this.sidebarService.toggle(true)
  }

  onItemSelection({ title }) {
    switch (title) {
      case 'Sair':
        this.logout()
        break
      case 'Mudar Senha':
        this.changePassword()
        break
      case 'Perfil':
        this.profile()
        break
    }
  }

  logout() {
    this.authService.logout()
  }

  changePassword() {
    this.dialogService.open<any>(ChangePasswordComponent)
  }

  profile() {
    this.dialogService.open<any>(ProfileComponent)
  }

  async fetchUser() {
    // const { id } = this.authService.decode()
    // this.userService.fetchUserById(id).subscribe(({ value }) => {
    //   value.tipoUsuario === 0
    //     ? (this.routerLogo = '/companies-report')
    //     : (this.routerLogo = '/security/user')

    //   return (this.user = { ...this.user, ...value })
    // })

    const { nome } = this.util.getUserToLocalStorage()
    return (this.user.nome = await nome)
  }

  redirecionaLogin() {
    this.router.navigate(['/security/user'])
  }
}
