import { Component, OnInit, OnDestroy } from '@angular/core'
import { GroupService } from 'src/app/services/group.service'
import { UserService } from 'src/app/services/user.service'
import { NbDialogRef } from '@nebular/theme'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { AlertService } from 'src/app/services/alert.service'
import { FormService } from 'src/app/services/form.service'
import { forkJoin } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { IGroup } from 'src/app/models/group'
import { User } from 'src/app/models/user'

@Component({
  selector: 'app-register-user-modal',
  templateUrl: './register-user-modal.component.html',
  styleUrls: ['./register-user-modal.component.scss'],
})
export class RegisterUserModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: User
  groups: IGroup[] = []
  userForm: FormGroup
  submitted = false
  editing = false

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public formService: FormService,
    private alert: AlertService,
    private nbDialogRef: NbDialogRef<RegisterUserModalComponent>
  ) {}

  ngOnInit() {
    forkJoin([this.groupService.getGroups()])
      .pipe(
        takeWhile(() => this.isAlive),
        map(resp => resp.map(e => e.value))
      )
      .subscribe(resp => {
        this.groups = resp[0]
      })

    this.userForm = this.formBuilder.group({
      nome: ['', Validators.required],
      cpf: ['', [Validators.required, this.formService.validaCPF]],
      telefone: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      idGrupo: ['', Validators.required],
    })

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.userForm.patchValue(this.data)
    }
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  onSubmit() {
    this.submitted = true
    if (this.userForm.invalid) {
      return
    }

    if (this.editing) {
      this.update()
    } else {
      this.save()
    }
  }

  save() {
    this.userService
      .save(this.userForm.value)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Usuário cadastrado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: true,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  update() {
    this.userService
      .updateUser(this.userForm.value, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Usuário atualizado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: this.editing,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  get f() {
    return this.userForm.controls
  }
}
