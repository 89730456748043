import { Component, OnInit } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { GroupService } from 'src/app/services/group.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user = {
    login: '',
    nome: '',
    email: '',
    telefone: '',
    tipoUsuario: 2,
    flagSituacao: '',
    cpf: '',
    idGrupo: '',
    nomeGrupo: '',
  }

  permissoes = []

  constructor(
    private nbDialogRef: NbDialogRef<ProfileComponent>,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    const arrUserData = localStorage.getItem('user-data')
    const { permissoes, ...userData } = JSON.parse(atob(arrUserData))

    this.groupService.getGroups().subscribe(({ value }) => {
      this.user = userData
      const grupo = value.find(e => e.id === this.user.idGrupo)
      this.user.nomeGrupo = grupo.nome
      this.permissoes = permissoes
    })
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }
}
