import { ServerService } from './server.service'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { IReturn } from '../models/return'
import { IUser } from '../models/newUser'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private server: ServerService) {}

  fetch(code: string) {
    return this.http.get<any>(this.server.url(`/Usuario?EmpresaId=${code}`))
  }

  update({ valor, id }) {
    return this.http.put<any>(
      this.server.url(`/ScoreParametro/configuracao-parametrosPeso/${id}`),
      { valor }
    )
  }

  save(user: IUser) {
    return this.http
      .post<IReturn>(this.server.url(`/Usuario`), user)
      .pipe(catchError(this.server.handleError))
  }

  getUsers() {
    return this.http
      .get<IReturn>(this.server.url(`/Usuario`))
      .pipe(catchError(this.server.handleError))
  }

  updateUser(user: IUser, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/Usuario/${id}`), user)
      .pipe(catchError(this.server.handleError))
  }

  enableDisableUser(id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/Usuario/ativarDesativar/${id}`), {})
      .pipe(catchError(this.server.handleError))
  }

  fetchUserById(id: string) {
    return this.http.get<any>(this.server.url(`/Usuario/${id}`))
  }

  fetchPermissions() {
    return this.http.get<any>(this.server.url(`/Login/permissoes`))
  }

  fetchDataUser() {
    return this.http.get<any>(this.server.url(`/Login/meusdados`))
  }

  allPermissions() {
    return this.http.get<any>(this.server.url(`/Dominio/EnumPermissao`))
  }
}
