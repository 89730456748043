import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'
import { NbDialogRef } from '@nebular/theme'
import { AlertService } from 'src/app/services/alert.service'
import { BannerService } from 'src/app/services/banner.service'
import { Util } from 'src/app/utils/util'
import { takeWhile } from 'rxjs/operators'
import { FileUploadValidators } from '@iplab/ngx-file-upload'

@Component({
  selector: 'app-create-banner-modal',
  templateUrl: './create-banner-modal.component.html',
  styleUrls: ['./create-banner-modal.component.scss'],
})
export class CreateBannerModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  bannerForm: FormGroup
  submitted = false
  editing = false

  private bannerControl = new FormControl(null, [
    Validators.required,
    FileUploadValidators.filesLimit(1),
  ])

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateBannerModalComponent>,
    private alert: AlertService,
    private util: Util,
    private bannerService: BannerService
  ) {}

  ngOnInit() {
    this.bannerForm = this.formBuilder.group(
      {
        banner: this.bannerControl,
        descricao: ['', Validators.required],
        dataInicioVigencia: [
          '',
          [Validators.required, this.formService.validaData],
        ],
        dataFimVigencia: ['', this.formService.validaData],
      },
      {
        validator: this.formService.startDateEndDate(
          'dataInicioVigencia',
          'dataFimVigencia'
        ),
      }
    )

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.bannerForm.patchValue({
        ...this.data,
        dataInicioVigencia: this.util.validDateAndApplyMaskDate(
          this.data.dataInicioVigencia
        ),
        dataFimVigencia: this.util.validDateAndApplyMaskDate(
          this.data.dataFimVigencia
        ),
      })

      this.f.banner.setValidators([])
      this.f.banner.updateValueAndValidity()
    }
  }

  onSubmit() {
    this.submitted = true
    if (this.bannerForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    let dataTemp = { ...this.bannerForm.value }
    dataTemp.dataInicioVigencia = this.util.convertDateTime(
      dataTemp.dataInicioVigencia
    )
    dataTemp.dataFimVigencia = this.util.convertDateTime(
      dataTemp.dataFimVigencia
    )
    dataTemp = this.util.convertJsonToFormData(dataTemp)

    if (this.editing) {
      this.update(dataTemp)
    } else {
      this.save(dataTemp)
    }
  }

  save(data) {
    this.bannerService
      .postBanner(data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Banner cadastrado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  update(data) {
    this.bannerService
      .putBanner(data, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Banner atualizado')
          this.submitted = false
          this.close({ editing: this.editing })
        },
        error => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  donwloadFile() {
    this.bannerService
      .downloadBanner(this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.util.convertBlobToBase64(resp)
      })
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  get f() {
    return this.bannerForm.controls
  }
}
