import { Component, OnInit, OnDestroy } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { AlertService } from 'src/app/services/alert.service'
import { Util } from 'src/app/utils/util'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { FormService } from 'src/app/services/form.service'
import { ServiceTypeService } from 'src/app/services/service-type.service'
import { takeWhile } from 'rxjs/operators'

@Component({
  selector: 'app-create-servicetype-modal',
  templateUrl: './create-servicetype-modal.component.html',
  styleUrls: ['./create-servicetype-modal.component.scss'],
})
export class CreateServicetypeModalComponent implements OnInit, OnDestroy {
  private isAlive = true
  data: any
  servicetypeForm: FormGroup
  submitted = false
  editing = false

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private nbDialogRef: NbDialogRef<CreateServicetypeModalComponent>,
    private alert: AlertService,
    private util: Util,
    private serviceTypeService: ServiceTypeService
  ) {}

  ngOnInit() {
    this.servicetypeForm = this.formBuilder.group({
      descricao: ['', Validators.required],
      foto: [],
    })

    const { foto, ...values } = this.data

    if (this.data && Object.values(this.data).length > 0) {
      this.editing = true
      this.servicetypeForm.patchValue(values)
      if (this.data.temFoto) {
        this.data.foto = this.serviceTypeService.getURLDownloadService(
          this.data.id
        )
      }
    }
  }

  ngOnDestroy() {
    this.isAlive = false
  }

  onSubmit() {
    this.submitted = true
    if (this.servicetypeForm.invalid) {
      this.formService.setFocusInInputsForm(this.f)
      return
    }

    if (this.editing) {
      this.update()
    } else {
      this.save()
    }
  }

  download() {
    this.serviceTypeService
      .downloadService(this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(resp => {
        this.util.convertBlobToBase64(resp)
      })
  }

  save() {
    const formData = this.util.convertJsonToFormData(this.servicetypeForm.value)
    this.serviceTypeService
      .postServiceType(formData)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Tipo de serviço cadastrado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: this.editing,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  update() {
    const formData = this.util.convertJsonToFormData(this.servicetypeForm.value)
    this.serviceTypeService
      .putServiceType(formData, this.data.id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        ({ value }) => {
          this.alert.success('Tipo de serviço atualizado')
          this.submitted = false
          this.close({
            oldValue: this.data,
            newValue: value,
            editing: this.editing,
          })
        },
        err => {
          this.submitted = false
        }
      )
  }

  close(item?) {
    this.nbDialogRef.close(item)
  }

  get f() {
    return this.servicetypeForm.controls
  }
}
