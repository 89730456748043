import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServerService } from './server.service'
import { catchError } from 'rxjs/operators'
import { IReturn } from '../models/return'

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient, private server: ServerService) {}

  getBanners() {
    return this.http
      .get<IReturn>(this.server.url(`/Banner`))
      .pipe(catchError(this.server.handleError))
  }

  deleteBanner(id: string) {
    return this.http
      .delete<IReturn>(this.server.url(`/Banner/${id}`))
      .pipe(catchError(this.server.handleError))
  }

  postBanner(params: any) {
    return this.http
      .post<IReturn>(this.server.url(`/Banner`), params)
      .pipe(catchError(this.server.handleError))
  }

  putBanner(params: any, id: string) {
    return this.http
      .put<IReturn>(this.server.url(`/Banner/${id}`), params)
      .pipe(catchError(this.server.handleError))
  }

  downloadBanner(code: string) {
    return this.http
      .get(this.server.url(`/Banner/download/${code}`), {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.server.handleError))
  }
}
