export class Login {
  userName: string
  password: string
  grantTypes: string
  resultado: number
}

export class LoginChangePassword {
  userName: string
  senhaAntiga: string
  senhaNova: string
}
