import { Injectable, ElementRef, Output, EventEmitter } from '@angular/core'
import { AbstractControl, FormGroup } from '@angular/forms'
import { LocalDataSource } from 'ng2-smart-table'
import { Util } from '../utils/util'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class FormService {
  @Output() modalClose = new EventEmitter()

  constructor(private util: Util) {}

  validaCPF(cpf: AbstractControl) {
    if (cpf.value === '' || cpf.value == null) {
      return null
    }
    let numeros, digitos, soma, i, resultado, digitos_iguais
    digitos_iguais = 1
    if (cpf.value.length < 11) {
      return { cpf: true }
    }
    for (i = 0; i < cpf.value.length - 1; i++) {
      if (cpf.value.charAt(i) != cpf.value.charAt(i + 1)) {
        digitos_iguais = 0
        break
      }
    }
    if (!digitos_iguais) {
      numeros = cpf.value.substring(0, 9)
      digitos = cpf.value.substring(9)
      soma = 0
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (resultado != digitos.charAt(0)) {
        return { cpf: true }
      }
      numeros = cpf.value.substring(0, 10)
      soma = 0
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
      if (resultado != digitos.charAt(1)) {
        return { cpf: true }
      }
      return null
    } else {
      return { cpf: true }
    }
  }

  validaTel(telefone: AbstractControl) {
    if (telefone.value === '' || telefone.value == null) {
      return null
    }

    let digitosiguais = 1
    for (let i = 0; i < telefone.value.length - 1; i++) {
      if (telefone.value.charAt(i) != telefone.value.charAt(i + 1)) {
        digitosiguais = 0
        break
      }
    }

    if (!digitosiguais) {
      const patter = new RegExp(/\d{11}/)
      if (patter.test(telefone.value)) {
        return null
      } else {
        return { telefone: true }
      }
    } else {
      return { telefone: true }
    }
  }

  validaData(data: AbstractControl) {
    if (data.value === '' || data.value == null) {
      return null
    }
    const dataTemp = moment(data.value, 'DDMMYYYY')
    return dataTemp.isValid() ? null : { data: true }
  }

  changeClass(input: AbstractControl, submitted = false, type = 'default') {
    if (submitted && input.errors) {
      return type === 'default' ? 'is-invalid' : 'danger'
    } else {
      if (input.pristine) {
        return 'basic'
      } else if (input.dirty && input.invalid) {
        return type === 'default' ? 'is-invalid' : 'danger'
      } else {
        return type === 'default' ? 'is-valid' : ''
      }
    }
  }

  setFocusInInputsForm(form: any) {
    for (const key of Object.keys(form)) {
      if (form[key].invalid) {
        const invalidControl: any = document.querySelector(
          `input[formControlName="${key}"]`
        )
        if (invalidControl) {
          invalidControl.focus()
          break
        }
      }
    }
  }

  filterTable(term, columns, data: LocalDataSource) {
    const { value } = term.target
    if (!this.util.isEmpty(value)) {
      const filtro = Object.keys(columns).map(key => {
        return { field: key, search: value }
      })
      data.setFilter(filtro, false)
    } else {
      data.reset(true)
      data.refresh()
    }
  }

  getField(form: FormGroup) {
    return form.controls
  }

  getFieldErros(form: FormGroup, field: string) {
    return form.get(`${field}`).errors
  }

  validFormAndShowsErrorInput(form: FormGroup) {
    if (form.invalid) {
      this.setFocusInInputsForm(this.getField(form))
      return true
    }
  }

  startDateEndDate(startDateControl: string, endDateControl: string) {
    return (formGroup: FormGroup) => {
      const startDate = formGroup.controls[startDateControl]
      const endDate = formGroup.controls[endDateControl]

      if (endDate.errors && !endDate.errors.dateInvalid) {
        return
      }

      let startDateValue = moment(startDate.value, 'DDMMYYYY')
      let endDateValue = moment(endDate.value, 'DDMMYYYY')

      if (
        startDateValue.isValid() &&
        endDateValue.isValid() &&
        !endDateValue.isAfter(startDateValue)
      ) {
        endDate.setErrors({ dateInvalid: true })
      } else {
        endDate.setErrors(null)
      }
    }
  }
}
