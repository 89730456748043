import { Component, OnInit } from '@angular/core'
import { AuthService } from './services/auth.service'
import { CurrentUserService } from './services/current-user.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Politintas-backoffice'
  isAuthenticated = false
  constructor(private _authService: AuthService) {
    this._authService.isLoggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.isAuthenticated = loggedIn
      }
    })
  }
}
