import { Component, OnInit } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'

@Component({
  selector: 'app-modal-confirm-deletion',
  templateUrl: './modal-confirm-deletion.component.html',
  styleUrls: ['./modal-confirm-deletion.component.scss'],
})
export class ModalConfirmDeletionComponent implements OnInit {
  data: any
  title: string = 'Exclusão de registro'
  msg: string = 'Tem certeza de que deseja excluir o registro selecionado?'
  constructor(
    private nbDialogRef: NbDialogRef<ModalConfirmDeletionComponent>
  ) {}

  ngOnInit() {
    if (this.data?.title && this.data?.msg) {
      this.title = this.data.title
      this.msg = this.data.msg
    }
  }

  close(item = false) {
    this.nbDialogRef.close({ confirm: item })
  }
}
