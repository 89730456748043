import { Injectable } from '@angular/core'
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router'
import { Observable } from 'rxjs'
import { CurrentUserService } from '../services/current-user.service'
import { AlertService } from '../services/alert.service'

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanLoad {
  constructor(
    private currentUserService: CurrentUserService,
    private alert: AlertService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.validRouterPermission(state.url)
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.validRouterPermission(route.path)
  }

  private validRouterPermission(route: any): boolean {
    let validRoute
    switch (route) {
      case 'painter':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarPintor
        )
        break
      case 'createPainter':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().CadastrarPintor
        )
        break
      case 'editPainter/:code':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().AtualizarPintor
        )
        break
      case 'servicetype':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarTipoServico
        )
        break
      case 'service-subtype':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarSubTipoServico
        )
        break
      case 'event':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarEvento
        )
        break
      case 'video':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarVideoAula
        )
        break
      case 'document':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarDocumento
        )
        break
      case 'banner':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarBanner
        )
        break
      case 'customers':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarClienteVisita
        )
        break
      case 'detailsCustomers/:code':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().AtualizarClienteVisita
        )
        break
      case 'user':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarUsuario
        )
        break
      case 'group':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarGrupo
        )
        break
      case 'group-permission':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarPermissoesGrupo
        )
        break
      case 'painterIndication':
        validRoute = this.currentUserService.validatePermissions(
          this.currentUserService.getEnumPermissions().ConsultarIndicacaoPintor
        )
        break

      default:
        // this.router.navigate(['/login'])
        // return true
        break
    }
    if (!validRoute) {
      this.alert.warning('Você não possui permissão para acessar, tal recurso')
    }
    return validRoute
  }
}
