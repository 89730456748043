import { Component, OnInit, OnDestroy } from '@angular/core'
import { NbMenuItem, NbMenuService } from '@nebular/theme'
import { takeWhile } from 'rxjs/operators'
import { CurrentUserService } from 'src/app/services/current-user.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private alive = true
  selectedItem: string

  items: NbMenuItem[] = [
    {
      title: 'Administrativo',
      icon: 'home-outline',
      hidden: !this.checkPermissionIn(
        'ConsultarPintor',
        'ConsultarClienteVisita',
        'ConsultarIndicacaoPintor',
        'ConsultarTipoServico',
        'ConsultarSubTipoServico',
        'ConsultarEvento',
        'ConsultarVideoAula',
        'ConsultarDocumento',
        'ConsultarBanner'
      ),
      children: [
        {
          title: 'Pintor',
          link: 'register/painter',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarPintor
          ),
        },
        {
          title: 'Visita do cliente',
          link: 'administration/customers',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarClienteVisita
          ),
        },
        {
          title: 'Indicações',
          link: 'administration/painterIndication',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions()
              .ConsultarIndicacaoPintor
          ),
        },
        {
          title: 'Eventos',
          link: 'register/event',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarEvento
          ),
        },
        {
          title: 'Vídeos',
          link: 'register/video',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarVideoAula
          ),
        },
        {
          title: 'Documentos',
          link: 'register/document',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarDocumento
          ),
        },
        {
          title: 'Banners',
          link: 'register/banner',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarBanner
          ),
        },
        {
          title: 'Serviço',
          selected: false,
          hidden: !this.checkPermissionIn(
            'ConsultarTipoServico',
            'ConsultarSubTipoServico'
          ),
          children: [
            {
              title: 'Tipo de serviço',
              link: 'register/servicetype',
              selected: false,
              hidden: !this.currentUserService.validatePermissions(
                this.currentUserService.getEnumPermissions()
                  .ConsultarTipoServico
              ),
            },
            {
              title: 'Subtipo de serviço',
              link: 'register/service-subtype',
              selected: false,
              hidden: !this.currentUserService.validatePermissions(
                this.currentUserService.getEnumPermissions()
                  .ConsultarSubTipoServico
              ),
            },
          ],
        },
      ],
    },
    {
      title: 'Segurança',
      icon: 'lock-outline',
      hidden: !this.checkPermissionIn(
        'ConsultarUsuario',
        'ConsultarGrupo',
        'ConsultarPermissoesGrupo'
      ),
      children: [
        {
          title: 'Usuário',
          link: 'security/user',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarUsuario
          ),
        },
        {
          title: 'Grupo',
          link: 'security/group',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions().ConsultarGrupo
          ),
        },
        {
          title: 'Permissões do grupo',
          link: 'security/group-permission',
          selected: false,
          hidden: !this.currentUserService.validatePermissions(
            this.currentUserService.getEnumPermissions()
              .ConsultarPermissoesGrupo
          ),
        },
      ],
    },
  ]

  constructor(
    private menuService: NbMenuService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    this.menuService
      .onItemClick()
      .pipe(takeWhile(() => this.alive))
      .subscribe(({ item }) => {
        this.resetSelectedItem(item.link)
      })
  }

  resetSelectedItem(route: string) {
    this.items.forEach(item => {
      const { children } = item
      if (children) {
        children.forEach((child: any) => {
          child.selected = route === child.link ? true : false
        })
      }
    })
  }

  checkPermissionIn(...permissions) {
    const totPermissions = permissions
      .map(permission =>
        this.currentUserService.validatePermissions(permission)
      )
      .filter(permission => permission).length
    if (totPermissions === 0) {
      return false
    } else {
      return true
    }
  }

  ngOnDestroy() {
    this.alive = false
  }
}
